var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$apollo.loading)?_c('Loading'):_c('div',{staticClass:"background-2 px-4 px-sm-0 component-edit_card"},[_c('v-card-actions',{staticClass:"py-2 px-0 px-sm-4 btn-actions pa-2 justify-end flex-wrap",staticStyle:{"gap":"16px 8px"}},[_c('v-btn',{attrs:{"text":"","icon":"","color":"error","disabled":_vm.$apollo.loading || _vm.saving,"title":_vm.$t('links_edit.deleteTitle')},on:{"click":_vm.deleteItem}},[_c('v-icon',[_vm._v("delete")])],1),_c('v-flex'),_c('v-btn',{attrs:{"height":"32","depressed":"","tile":"","outlined":"","title":_vm.$t('links_edit.undoTitle'),"disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.update}},[_c('v-icon',[_vm._v("undo")]),_vm._v(" "+_vm._s(_vm.$t('links_edit.undoBtn'))+" ")],1),_c('v-btn',{staticClass:"px-2 ml-0",attrs:{"height":"32","depressed":"","tile":"","color":"primary","dark":!_vm.dirty ? false : true,"disabled":!_vm.dirty,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('links_edit.saveBtn'))+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"size":"16"}},[_vm._v("save")])],1)],1),_c('v-divider'),_c('v-card',{attrs:{"color":"transparent mx-auto","max-width":"588px","elevation":"0","tile":""}},[_c('v-card-text',{attrs:{"id":"links-edit__card-text"}},[_c('div',{staticClass:"background title-form mb-4 pa-2 pl-4"},[_vm._v(" "+_vm._s(_vm.$t('links_edit.title'))+" ")]),_c('Fields',{attrs:{"id":"link-edit-fields","schema":{
          title: {
            type: 'string',
            label: _vm.$t('links_edit.schema.title.label'),
            description: _vm.$t('links_edit.schema.title.description'),
            newDesign: true
          },
          type: {
            label: _vm.$t('links_edit.schema.type.label'),
            type: 'select',
            sizeSmall: 12,
            fieldOptions: {
              options: _vm.$t('links_edit.schema.type.options')
            },
            newDesign: true
          },
          position: {
            type: 'number',
            label: _vm.$t('links_edit.schema.position.label'),
            sizeSmall: 12,
            newDesign: true
          },
          icon: {
            type: 'string',
            label: _vm.$t('links_edit.schema.icon.label'),
            description: _vm.$t('links_edit.schema.icon.description'),
            sizeSmall: 12,
            fieldOptions: {
              icon: _vm.normalizedIcon
            },
            optional: true,
            newDesign: true
          }
        },"disabled":_vm.$apollo.loading || _vm.saving,"environmentVariables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}}),(_vm.link.type !== 'divider')?_c('div',{staticClass:"background title-form mb-4 pa-2 pl-4"},[_vm._v(" "+_vm._s(_vm.$t('links_edit.links'))+" ")]):_vm._e(),_c('draggable',{attrs:{"handle":".link-handle","animation":200,"scroll-sensitivity":128,"force-fallback":true},model:{value:(_vm.linkFields),callback:function ($$v) {_vm.linkFields=$$v},expression:"linkFields"}},[_c('v-slide-y-transition',{attrs:{"group":""}},_vm._l((_vm.linkFields),function(field,index){return _c('LinkFieldEdit',{key:field.linkId || index,attrs:{"link":field,"type":_vm.link.type,"index":index,"disabled":_vm.$apollo.loading || _vm.saving,"environmentVariables":{ environmentId: _vm.environmentId },"validationErrors":{}},on:{"input":function($event){return _vm.handleFieldChange(index, $event)},"delete":function($event){return _vm.handleFieldDelete(index)}}})}),1)],1),(_vm.link.type === 'category')?_c('v-btn',{attrs:{"outlined":"","block":"","color":"primary"},on:{"click":_vm.addField}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('links_edit.addLinkBtn'))+" "),_c('v-icon',{staticClass:"mr-8"},[_vm._v("add")])],1)]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }